





































import { Component, Vue, Ref } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
import { hist_preguntas_paciente } from "@/shared/dtos/hist_preguntas_paciente";

@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
    pacienteHistPreguntas: () =>
      import("@/views/pacientes/historial/paciente-hist-preguntas.vue"),
    PacienteFichaOpciones: () =>
      import("@/views/pacientes/paciente-ficha-opciones.vue"),
    CompQuestion: () => import("@/views/entrevista/CompQuestion.vue"),
  },
})
export default class pacienteHistPreguntasHandler extends Vue {
  public id_paciente!: number;
  public dialogo: boolean = false;
  public created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
  }

  public async save_nuevas_preguntas() {
    //si la colnsulta se abre en modo edición no guardamos nada
    var modulo = await import("@/store/modules/cabezera_pregunta-module");
    var modulo2 = await import(
      "@/store/modules/hist_preguntas_paciente-module"
    );
    var act3 = await import("@/shared/services/message-service");
    var cabezera_preguntaModule = modulo.default;
    var hist_preguntas_pacienteModule = modulo2.default;
    var ssmMessageService = act3.ssmMessageService;
    let Listhist: hist_preguntas_paciente[] = [];
    for (
      let i = 0;
      i < cabezera_preguntaModule.cabezera_preguntas.length;
      i++
    ) {
      const element = cabezera_preguntaModule.cabezera_preguntas[i];

      if (element.itemseleccionado !== undefined) {
        if (element.itemseleccionado > 0) {
          let hist: hist_preguntas_paciente = new hist_preguntas_paciente();
          hist.id_pregunta = element.id;
          hist.id_respuesta = element.itemseleccionado;
          hist.fecha = new Date();
          hist.id_paciente = this.id_paciente;
          hist.id = 0;
          Listhist.push(hist);
        }
      }
    }

    if (Listhist.length > 0) {
      await hist_preguntas_pacienteModule
        .guardarhist_preguntas_paciente(Listhist)
        .then(() => {
          ssmMessageService.toastsuccesful("Preguntas guardadas");
          this.dialogo = false;
          hist_preguntas_pacienteModule.gethist_preguntas_pacientes(
            this.id_paciente ? this.id_paciente : 0
          );
        })
        .catch(() =>
          ssmMessageService.toastinfo("No se han podido guardar las preguntas")
        );
    } else {
    }
  }
}
